import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { onCheckUser } from "../redux/actions";
import { Header } from "../components/rarity/rarity-header";
import { Banner2 } from "../components/banner2";
import { SubFooter } from "../components/sub-footer";
import { Filter } from "../components/rarity/rarity-filter";

const Rarity = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const verify = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (account) {
      dispatch(
        onCheckUser({
          address: account,
        })
      );
    }
  }, [account]);

  return (
    <div>
      <Header
        flag={verify.user_check && !verify.signup_success ? true : false}
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <Filter
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />          
      <Banner2 backgroundImage="banner2"/>
      <SubFooter />
    </div>
  );
};

export default Rarity;

import { useState, useEffect, useRef } from "react";
import { Card } from "./rarity-card";
import Carousel from 'react-grid-carousel'
import styled from 'styled-components'
import ReactLoading from "react-loading";

const CustomBtn = styled.div`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 40px;
  color: #d0d0d0;
  opacity: 0.6;
  cursor: pointer;
  bottom: -10%;
  z-index: 10;
  transition: all 0.25s;
  transform: ${({ type }) =>
    `translateY(-50%) ${type === 'left' ? 'rotate(180deg)' : ''}`};
  left: ${({ type }) => (type === 'left' ? '20px' : 'initial')};
  right: ${({ type }) => (type === 'right' ? '20px' : 'initial')};

  &:hover {    
    color: #fff;
    opacity: 1;
  }  
`
export const CardCarousel = (props) => {
    const { raritiesData } = props;
    const [gap, setGap] = useState(20);
    const [pages, setPages] = useState(1);
    const [cardsPerPage, setCardsPerPage] = useState(25);
    const [loading, setLoading] = useState(true);
    const currentPage = useRef(1);

    useEffect(() => {
        if(gap == 20)
            setGap(19);
        else
            setGap(20);
        currentPage.current = 1;
        getCardsPerPage();
        var pageCalculate = Math.ceil(raritiesData.length / cardsPerPage);
        setPages(pageCalculate);  
        document.getElementById("pageString").innerHTML = currentPage.current + "/" + pageCalculate;    
        setLoading(true);
        setTimeout(function(){
            setLoading(false);
        },1000)
    }, [raritiesData]);

    const getCardsPerPage = () => {
        if(window.innerWidth >= 750)
            setCardsPerPage(25);
        else if(window.innerWidth >= 499)
            setCardsPerPage(9);
        else
            setCardsPerPage(5);
    }

    const rarityTags = () => {        
        return raritiesData.map((item, key) => {
            var nameArray = item.name.split("#");
            var directory = item.collection.replace(" ", "-");
            var imageDir = "img/images/" + directory + "/" + nameArray[1] + ".jpg";            
            return (
              <Carousel.Item key={key}>
                <Card imgURL={imageDir} title={item.name} number={item.rank} link={"https://opensea.io/assets/0x7cf7f5383496e17cf742464606aeea24f715dbde/" + item.metadata_id}/>
              </Carousel.Item>
            )
        })
    }

    const handlePagePrev = () => {
        currentPage.current = currentPage.current - 1;   
        document.getElementById("pageString").innerHTML = currentPage.current + "/" + pages;
    }
    const handlePageNext = () => {        
        currentPage.current = currentPage.current + 1;
        document.getElementById("pageString").innerHTML = currentPage.current + "/" + pages;
    }
    return (

        <div>                    
            <div style={{display: loading ? "block" : "none"}}>
                <ReactLoading
                    type="spin"
                    color="#0affff"
                    height={120}
                    width={120}
                    className="loadingDiv"
                />
            </div>
            <div style={{display: loading ? "none" : "block"}}>            
                <Carousel
                    cols={5} 
                    rows={5} 
                    gap={gap} 
                    arrowLeft={<CustomBtn type="left" onClick={handlePagePrev}> &#10095; </CustomBtn>}
                    arrowRight={<CustomBtn type="right" onClick={handlePageNext}> &#10095; </CustomBtn>}
                    mobileBreakpoint={200}
                    responsiveLayout={[              
                        { breakpoint: 750, cols: 3, rows: 3},
                        { breakpoint: 499, cols: 1, rows: 5},
                    ]}
                    containerClassName="cardList"
                >
                    {rarityTags()}            
                </Carousel>
                <span id="pageString" className="pageString">1/{pages}</span>
            </div>
        </div>
    );
};
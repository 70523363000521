import { Link } from "react-router-dom";

export const Roadmap3 = (props) => {
  return (
    <div id="roadmap" className="text-center">
      <div className="container">
        <div className="collection2">
          <h1 className="r-header">MEET OUR COLLECTION 3</h1>
          <p>By DAYTONER x CRACK</p>
          <p>999 NFTs AVAILABLE THE 07/14</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/4HCeEIkuFKw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="video"
          ></iframe>
        </div>
      </div>

      <div>
        <div className="roadmap3">
          <h1 className="r-header">
            CHECK YOUR M9E RARITY HERE
          </h1>
          <div className="a-button-div text-center">
            <Link
              to="/rarity"
              rel="rarity"
              className="btn btn-rarity btn-lg page-scroll"
            >
              RARITY
            </Link>
          </div>
        </div>

        <div className="roadmap3">
          <h1 className="r-header">
            DISCOVER OUR M9E COLLECTIBLES MECHANISM
          </h1>
          <div className="a-button-div text-center">
            <Link
              to="/collectibles"
              rel="rarity"
              className="btn btn-rarity btn-lg page-scroll"
            >
              CHECK HERE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

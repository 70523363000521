import logo2 from "../assets/images/M9E decals1.png";

export const Banner2 = (props) => {
  const {backgroundImage} = props
  return (
    <div id={backgroundImage}>
      <div className="logo2">
        <img src={logo2} alt="logo2"></img>
      </div>
    </div>
  );
};

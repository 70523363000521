import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onGetCollectibles, onCheckCollectibles } from "../../redux/actions";

export const CollectiblesBody = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;
  const navigate = useNavigate();

  //states
  const collectiblesData = useSelector((state) => state.collectibles);
  const [optionIndex, setOptionIndex] = useState();
  const [tableData, setTableData] = useState(Array.from({ length: 9 }, () => Array.from({ length: 9 }, () => "")))
  const [selectedOption, setSelectedOption] = useState();
  const [optionValue, setOptionValue] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [selectedtokenIDs, setSelectedTokenIDs] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [selectedTokenImages, setSelectedTokenImages] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const dispatch = useDispatch();

  const handleConnectWallet = async () => {
    if (window.ethereum) {
      await window.ethereum.enable();
      setMetamaskConnnected(true);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (account) {
      dispatch(
        onGetCollectibles({
          address: account,
        })
      );
    }
  }, [account]);

  useEffect(() => {
    if (collectiblesData.gettingFailed == false) {
      navigate("/home");
    }
    // var arr = [];
    // for (var i = 0; i < 9; ++i) {
    //   var columns = [];
    //   for (var j = 0; j < 9; ++j) {
    //     columns[j] = "";
    //   }
    //   arr[i] = columns;
    // }

    const arr = [...tableData];
    if (collectiblesData["tokenInfo"].length != 0) {
      arr[0][optionIndex - 1] = collectiblesData["tokenInfo"].background;
      arr[1][optionIndex - 1] = collectiblesData["tokenInfo"].namenine;
      arr[2][optionIndex - 1] = collectiblesData["tokenInfo"].head;
      arr[3][optionIndex - 1] = collectiblesData["tokenInfo"].weapon;
      arr[4][optionIndex - 1] = collectiblesData["tokenInfo"].strapspant;
      arr[5][optionIndex - 1] = collectiblesData["tokenInfo"].shoelace;
      arr[6][optionIndex - 1] = collectiblesData["tokenInfo"].goldcolor;
      arr[7][optionIndex - 1] = collectiblesData["tokenInfo"].holocolor;
      arr[8][optionIndex - 1] = collectiblesData["tokenInfo"].diamondhands;
      setTableData(arr);
    }
  }, [collectiblesData]);

  const TableBody = () => {
    let tableRows = [];
    for (var i = 1; i < 10; i++) {
      tableRows.push(
        <tr key={i}>
          <th scope="row">
            <div>TRAIT #{i}</div>
          </th>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][0].length > 7
                  ? tableData[i - 1][0].substring(0, 6) + ".."
                  : tableData[i - 1][0]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][1].length > 7
                  ? tableData[i - 1][1].substring(0, 6) + ".."
                  : tableData[i - 1][1]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][2].length > 7
                  ? tableData[i - 1][2].substring(0, 6) + ".."
                  : tableData[i - 1][2]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][3].length > 7
                  ? tableData[i - 1][3].substring(0, 6) + ".."
                  : tableData[i - 1][3]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][4].length > 7
                  ? tableData[i - 1][4].substring(0, 6) + ".."
                  : tableData[i - 1][4]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][5].length > 7
                  ? tableData[i - 1][5].substring(0, 6) + ".."
                  : tableData[i - 1][5]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][6].length > 7
                  ? tableData[i - 1][6].substring(0, 6) + ".."
                  : tableData[i - 1][6]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][7].length > 7
                  ? tableData[i - 1][7].substring(0, 6) + ".."
                  : tableData[i - 1][7]
                : ""}
            </div>
          </td>
          <td>
            <div>
              {tableData.length == 9
                ? tableData[i - 1][8].length > 7
                  ? tableData[i - 1][8].substring(0, 6) + ".."
                  : tableData[i - 1][8]
                : ""}
            </div>
          </td>
        </tr>
      );
    }
    return tableRows;
  };

  const TableHeader = () => {
    let tableHeaders = [];
    for (var i = 1; i < 10; i++) {
      tableHeaders.push(
        <th scope="col" key={i}>
          <div>Collection{i}</div>
          <div className="tokenID">{selectedtokenIDs[i - 1]}</div>
          {selectedTokenImages[i - 1] != "" &&
            <img src={selectedTokenImages[i - 1]} className="tokenImage" />
          }
        </th>
      );
    }
    return tableHeaders;
  };

  const Collectibles = () => {
    let collectibles = [];
    for (let index = 1; index < 10; index++) {
      collectibles.push(
        <div className="d-flex mb-3" key={index}>
          <div className="collection-item">
            <h2 style={{ color: "#05ffff" }}>Select your M9Ξ</h2>
          </div>
          <div className="collection-item">
            <h2>COLLECTION {index}</h2>
          </div>
          <div className="select">
            <select
              name=""
              id="old-select"
              value={optionValue[index - 1]}
              // defaultValue=""
              onChange={(e) =>
                getOptionValue(
                  e.target.value,
                  index,
                  e.nativeEvent.target.selectedIndex
                )
              }
            >
              <Option items={collectiblesData["collectiblesData"][index - 1]} />
            </select>
          </div>
        </div>
      );
    }
    return collectibles;
  };

  const Option = (items) => {
    let options = [];
    options.push(
      <option value="" disabled hidden key="0">
        select..
      </option>
    );
    if (items.items != undefined) {
      items.items.map((item, i) => {
        options.push(
          <option value={item.tokenId} key={i + 1}>
            {item.name}
          </option>
        );
      });
    }
    return options;
  };

  const getOptionValue = (value, index, selectedOption) => {
    const optionValues = optionValue;
    optionValues[index - 1] = value;
    setOptionValue(optionValues);
    setOptionIndex(index);
    setSelectedOption(selectedOption);

    const selectedTokenId = selectedtokenIDs;
    selectedTokenId[index - 1] =
      collectiblesData["collectiblesData"][index - 1][selectedOption - 1].name;
    setSelectedTokenIDs(selectedTokenId);

    const selectedTokenImage = selectedTokenImages;
    selectedTokenImage[index - 1] =
      collectiblesData["collectiblesData"][index - 1][selectedOption - 1].image;
    setSelectedTokenImages(selectedTokenImage);

    dispatch(
      onCheckCollectibles({
        tokenId: value,
      })
    );
  };

  return (
    <div id="verify" className="text-center">
      <div className="verify-container">
        <div className="step1">
          <div className="row">
            <div className="col-md-3 d-flex align-items-center">
              <div className="step-title2">
                <h1>STEP 1:</h1>
              </div>
            </div>
            <div className="col-md-9 d-flex">
              <div className="collectibles-connect-button">
                {account ? (
                  <div className="collectibles-address">
                    {account &&
                      `${account.substring(0, 6)}...${account.substring(
                        account.length - 4
                      )}`}
                  </div>
                ) : (
                  <button
                    className="btn btn-collectibles-button btn-lg page-scroll balance"
                    onClick={handleConnectWallet}
                  >
                    CONNECT WALLET
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="step2">
          <div className="row">
            <div className="col-md-3">
              <div className="step-title2">
                <h1>STEP 2:</h1>
              </div>
            </div>
            <div className="col-md-9">
              <Collectibles />
            </div>
          </div>
        </div>
        <div className="step3 position-relative">
          <div className="row mb-3">
            <div className="col-md-3 d-flex">
              <div className="step-title2">
                <h1 className="text-center">STEP 3:</h1>
              </div>
            </div>
            <div className="col-md-9">
              <div className="d-flex">
                <div className="step-content">
                  <h1>RESULT</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <div></div>
                  </th>
                  <TableHeader />
                </tr>
              </thead>
              <tbody>
                <TableBody />
              </tbody>
            </table>
          </div>
          <h3 className="text-left my-5">Description:</h3>
          <div className="d-flex align-items-center my-4">
            <h3>1 BACKGROUNDS </h3>
            <p>(result can be: NORMAL or SPECIAL)</p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>2 THE NUMBER 9 </h3>
            <p>(having a 9 on the name)(result can be: YES or NO)</p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>3 HEAD SHAPE </h3>
            <p>
              (matching colors not required)(result can be: ONI or MECHA or M9Ξ
              or ETHER)
            </p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>4 WEAPON SHAPE </h3>
            <p>
              (matching colors not required)(result can be: KATANAS or BASEBALL
              or NUNCHUCK or SICKLE)
            </p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>5 [STRAPSPANT]COLOR </h3>
            <p>(result can be: Trait name)</p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>6 [SHOELACE]COLOR </h3>
            <p>(result can be: Trait name)</p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>7 GOLD COLOR </h3>
            <p>(result can be: YES or NO)</p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>8 HOLOGRAPHIC COLOR </h3>
            <p>(result can be: YES or NO)</p>
          </div>
          <div className="d-flex align-items-center my-4">
            <h3>9 DIAMOND HANDS </h3>
            <p>(result can be: YES or NO)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

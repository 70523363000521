import { Header } from "../components/collectibles/collectibles-header";
import { Banner2 } from "../components/banner2";
import { SubFooter } from "../components/sub-footer";
import { CollectiblesBody } from "../components/collectibles/collectibles-body";

const Collectibles = (props) => {
  const { metamaskConnected, account, setMetamaskConnnected } = props;

  return (
    <div>
      <Header
        flag={true}
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <CollectiblesBody
        metamaskConnected={metamaskConnected}
        setMetamaskConnnected={setMetamaskConnnected}
        account={account}
      />
      <Banner2 backgroundImage="banner2"/>
      <SubFooter />
    </div>
  );
};

export default Collectibles;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { onDiscordVerify, onSignUp, onCheckUser } from "../../redux/actions";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

export const Card = (props) => {
  const { imgURL, title, number, link} = props;
  const [loading, setLoading] = useState(true);

  const imageLoaded = () => {            
    setLoading(false);    
  }

  return (
    <a href={ link } target="blank">
      <div className="text-center rarity_card">         
        <div style={{display: loading ? "block" : "none"}} className="loadingNFTDiv">

        </div>
        <div style={{display: loading ? "none" : "block"}}>
          <img src={ imgURL } onLoad={imageLoaded}></img>
        </div>
        <h3 className="nftName">{ title }</h3>
        <h3 className="rank"># { number }</h3>
      </div>
    </a>
  );
};

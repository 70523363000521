import {
    GET_RARITIES_SUCCESS,
    CHECK_RARITIES_SUCCESS,    
    GET_RARITIES_FAILED
} from "../shared/ActionTypes.js";

const INIT_STATE = {
    raritiesData: [],    
    gettingFailed: null,
};

const raritiesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_RARITIES_SUCCESS: {
            return {
                ...state,
                raritiesData: action.payload.rarities,
                gettingFailed: action.payload.gettingFailed,
            };
        }
        case CHECK_RARITIES_SUCCESS: {
            return {
                ...state,
                gettingFailed: action.payload.gettingFailed,
            };
        }
        case GET_RARITIES_FAILED: {
            return {
                ...state,
                gettingFailed: action.payload.gettingFailed,
            }
        }
        default:
            return state;
    }
};
export default raritiesReducer;
export const TermsContent = (props) => {
  return (
    <div id="termsContent" className="text-center">
      <div className="container">
        <div>
          <div className="terms-title">
            <h1>TERMS & CONDITIONS</h1>
            <p className="terms-mini">Last Updated: November 09, 2021 </p>
          </div>
          <div className="terms-main">
            <div className="paragraph top-intro">
              <p>
                This website is operated by MASTER9EYES. Throughout the site,
                the term «Mas-ter9eyes», «we», «us» or «our» as the context may
                require, refer to MASTER9EYES. By visiting our site and/or
                purchasing something from us, you engage in our Service and
                agree to be bound by the following terms and conditions.
              </p>
              <p>
                We reserve the right to update, change or replace any part of
                these Terms of services without prior written notice at any
                time, and it is your responsibility to periodically review these
                Terms of Uses to stay informed of updates. Any changes to the
                Terms will be in effect as of the《last updated》referenced on
                the site.Your continued use of this site after the last updated
                dates will constitute your acceptance of agreement.
              </p>
            </div>
            <div className="paragraph">
              <p>
                By visiting the website of our products merchants, in particular
                www.opensea.io, you also engage and agree to be bound by their
                terms and conditions.
              </p>
              <p>
                This website is for informational purposes and serves as a
                binding contract for purchasers of MASTER9EYES NFTs.
              </p>
            </div>
            <div className="paragraph">
              <p>
                MASTER9EYES is a generative collection of digital artworks
                (NFTs) housed and run on the Ethereum Network. Users and
                Collector of these NFTs are solely responsible for the safety
                and the management of their own private assets, which include
                but are not limited to Ethereum Wallets, validating all
                transactions and contracts generated by this website prior to
                and after purchases.
              </p>
            </div>
            <div className="paragraph">
              <p>
                Users certify through purchase that they understand that, as the
                MASTER9EYES smart contract runs on the Ethereum network and is
                bound by their system and terms, there is no ability to undo,
                reverse, or restore any transactions.
              </p>
              <p>
                Any connected services included this website are provided "as
                is" and "as available" without any warranty of any kind. Use of
                this website constitutes your agreement that you are accepting
                sole responsibility for any and all transactions involving
                MASTER9EYES digital collectibles.
              </p>
            </div>
            <div className="paragraph">
              <p>
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your state or province of
                residence.
              </p>
              <p>
                You may not use our products and/or services for any illegal or
                unauthorized purpose nor may you, in the use of the Service,
                violate any laws in your jurisdiction (including but not limited
                to copyright laws).
              </p>
            </div>
            <h4>01.OWNERSHIP</h4>
            <div className="paragraph">
              <p>
                When you purchase your MASTER9EYES NFT, You Own the NFT: the
                ownership is transferred to you on the Ethereum blockchain for
                that individual piece of Art combination of traits and number.
                Ownership of the NFT is ruled by the smart contract and the
                Ethereum Network terms. We, MASTER9EYES, have no ability to
                alter, freeze, seize or modify the ownership of any MASTER9EYES
                NFT.
              </p>
            </div>
            <div className="paragraph">
              <h4>02.Personal Usage</h4>
              <p>
                Subject to your continued acceptance with these terms,
                MASTER9EYES allows you a worldwide, royalty-free license to use,
                copy and display the purchased Art, along with any derivative
                artwork or extensions that you could create or use, limited by
                the following purposes : (i) for your own personal, non
                commercial use; (ii) as part of a marketplace that allows the
                purchase and sales of you MASTER9EYES / NFT, as long as the
                marketplace cryptographically verifies that you are the owner,
                to ensure that only the actual owner have the right to display
                the Art; or (iii) as part of a third party website or
                application that permits the inclusion, involvement or
                participation of your MASTER9EYES, provided that the website or
                the application cryptographically verifies each MASTER9EYES
                owner's right to display the Art for their MASTER9EYES, to
                ensure that only the actual owner can display the Art, and
                provided that the Art is no longer visible once the actual owner
                of the MASTER9EYES leave the website or application.
              </p>
            </div>
            <div className="paragraph">
              <h4>03.Commercial Usage</h4>
              <p>
                Subject to actual owner of MASTER9EYES NFTs continued compliance
                with these Terms, MASTER9EYES grant you an unlimited, worldwide
                license to use, copy and display the purchased Art for the
                purpose of creating derivative works based upon the Art
                (《COMMERCIAL USAGE》) Examples of specified Commercial Use
                would be the use of the Art to produce and sell physical
                merchandise products (T-Shirt, Posters, etc.) displaying copie
                of the purchased Art. Please be aware that nothing in this
                section will be deemed to restrict you from (i) owning or
                operating a marketplace that permits the use and sale of
                MASTER9EYES generally, provided that the marketplace
                cryptographically verifies each MASTER9EYES owner's right to
                display the Art of their MASTER9EYES to ensure that only the
                actual owner can display the Art; (ii) owning or operating a
                third party website or application that permits the inclusion,
                involvement, or participation of MASTER9EYES generally, provided
                that the third party website or application cryptographically
                verifies each MASTER9EYES owner's rights to display the Art for
                their MASTER9EYES to ensure that only the actual owner can
                display the Art, and provided that the Art is no longer visible
                once the owner of the Purchased MASTER9EYES leaves the
                website/application; or (iii) earning revenue from any of the
                foregoing.
              </p>
            </div>
            <div className="paragraph">
              <p>
                Further, MASTER9EYES reserves the right to use ANY character,
                including figurine, print or digital advertising, or any purely
                creative media (including short film, anime, etc.) in support of
                the MASTER9EYES community and message.
              </p>
              <p>
                However, you cannot use the artwork in connection with images of
                hatred, violence or any other inappropriate behavior. The
                License granted in above only applies to the extent that you
                continue to own the relevant NFT. If at any time you trade,
                donate, giveaway, transfer or otherwise dispose of your NFT for
                any reason, the license granted above will immediately expire,
                without notice, and you will have no further right in or to the
                artwork of this NFT.
              </p>
            </div>
            <div className="paragraph">
              <h4>04.MASTER9EYES IP </h4>
              <p>
                Other than the rights to the Art, nothing gives you any rights
                to any other trademarks or other intellectual property rights
                belonging to MASTER9EYES including, without limitation
                Master9Eyes, M9Ξ, M9Ξ and the associated logos. All of these
                rights are expressly reserved in the name of MASTER9EYES.
              </p>
            </div>
            <div className="paragraph">
              <h4>05.Fees and Payment </h4>
              <p>
                A. If you elect to purchase a Master9Eyes through the Site, any
                financial transactions that you engage in will be conducted
                solely through the Ethereum network. We will have no insight
                into or control over these payments or transactions, nor do we
                have the ability to reverse any transactions. We will have no
                liability to you or to any third party for any claims or damages
                that may arise as a result of any transactions that you engage
                or any other transactions that you conduct via the Ethereum
                network.
              </p>
            </div>
            <div className="paragraph">
              <p>
                B. Ethereum requires the payment of a transaction fee (a "Gas
                Fee") for every transaction that occurs on the Ethereum network.
                The Gas Fee funds the network of computers that run the
                decentralized Ethereum network. This means that you will need to
                pay a Gas Fee for each transaction.
              </p>
            </div>
            <div className="paragraph">
              <h4>06.Disclaimers </h4>
              <p>
                A. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND
                USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS
                PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY
                KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS
                WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING
                THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE
                SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR
                RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
                WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT
                OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE SITE
                WILL MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE
                SITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
                (III) USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE,
                (III) THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE
                AVAILABLE ON OR THROUGH THE SITE ARE FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU DISCLOSE WHEN
                YOU USE THE SITE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW
                THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS,
                SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
              </p>
            </div>
            <div className="paragraph">
              <p>
                B. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
                INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT
                WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF
                SECURITY UNLESS IT IS DUE TO OUR WILLFULL MISCONDUCT.
              </p>
            </div>
            <div className="paragraph">
              <p>
                C. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES
                YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK NOR
                DO WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES REGARDING ANY
                SMART CONTRACTS.
              </p>
            </div>
            <div className="paragraph">
              <h4>07.Limitation of Liability </h4>
              <p>
                A. YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR
                TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR,
                HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING,
                WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED
                DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS
                REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE
                GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
            </div>
            <div className="paragraph">
              <p>
                B. YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY
                AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR
                ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY
                PORTION OF THE SITE, WHETHER IN CONTRACT, TORT, STRICT
                LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER
                OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE
                12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $500.
              </p>
            </div>
            <div className="paragraph">
              <p>
                C. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE
                AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON
                THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH
                HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT
                THESE LIMITATIONS.
              </p>
            </div>
            <div className="paragraph">
              <h4>08.Changes to the Terms and Conditions </h4>
              <p>
                We may make changes to the Terms at our discretion. Please check
                these Terms periodically for changes. Any changes to the Terms
                will apply on the date that they are made, and your continued
                access to or use after the Terms have been updated will
                constitute your binding acceptance of the updates. If you do not
                agree to any revised Terms, you may not access or use the Site.
              </p>
            </div>
            <div className="paragraph">
              <h4>09.Children </h4>
              <p>
                Our Site is not intended for children. You must be at least 18
                years old to access this Site or purchase a Master9Eyes. If you
                are under 18 years old you are not permitted to use this Site
                for any reason. By accessing the Site, you represent and warrant
                that you are at least 18 years of age.
              </p>
            </div>
            <div className="paragraph">
              <h4>10.Dispute Resolution; Arbitration </h4>
              <p>
                All disputes arising out of or in connection with these Terms,
                including without limitation your access or use of the Site, or
                to any products sold or distributed through the Site, will be
                referred to and finally resolved by arbitration under the rules
                of the Canadian Arbitration Association. The case will be
                adjudicated by a single arbitrator and will be administered by
                the Canadian Arbitration Association in accordance with its
                applicable rules. Each party will cover its own fees and costs
                associated with the arbitration proceedings. The place of
                arbitration will be Montreal, Canada. The award of the
                arbitrator will be final and binding, and any judgment on the
                award rendered by the arbitrator may be entered in any court of
                competent jurisdiction. Notwithstanding the foregoing, we may
                seek and obtain injunctive relief in any jurisdiction in any
                court of competent jurisdiction.
              </p>
            </div>
            <div className="paragraph">
              <p>
                WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE
                TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SITE
                OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE SITE, OR THE
                SMART CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO
                HAVE A TRIAL BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR
                RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY
                LAWSUIT, INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS
                INVOLVING ANY SUCH DISPUTE.
              </p>
            </div>
            <h4>11.Feedback </h4>
            <div className="paragraph">
              <p>
                You can submit comments, bug reports, ideas about the site or
                the project. By submitting any feedback, you agree that we are
                free to use them in any way we choose without additional
                compensation to you and you hereby grant us a perpetual,
                irrevocable, nonexclusive worldwide license to incorporate and
                use the feedback for any purpose.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

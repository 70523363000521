import {
    GET_RARITIES_SUCCESS,
    CHECK_RARITIES_SUCCESS,    
    GET_RARITIES_FAILED
} from "../shared/ActionTypes.js";
import authAxios from "../apis/axios";
import swal from 'sweetalert';

export const onGetRarities = ({
    collection, background, backpack, pant, skeleton, weapon, strapspant, jacket, head, shoes, shoelace, tokenId
}) => {    
    return async (dispatch) => {
        const body = {
            collection, background, backpack, pant, skeleton, weapon, strapspant, jacket, head, shoes, shoelace, tokenId
        };
        try {
            const res = await authAxios.post("/getRarities", body);
            console.log(res.data.rarities);
            if (res.data.success == false) {
                swal("Sorry!", res.data.message, "error");
            } else {
                if(res.data.rarities.length != 0){
                    dispatch({
                        type: GET_RARITIES_SUCCESS,
                        payload: {
                            rarities: res.data.rarities,
                            gettingFailed: false
                        },
                    });
                } else {
                    dispatch({
                        type: GET_RARITIES_FAILED,
                        payload: {
                            gettingFailed: true,
                        },
                    });
                }
            }
        } catch (err) {
            swal("Sorry!", "Network Error", "error");
            console.log("error!!!!", err);
        }
    };
};

